<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import authStore from "$lib/stores/authStore";
  import { page } from "$app/stores";
  import LL from "$i18n/i18n-svelte";
  import Spinner from "$src/lib/components/ui/components/Spinner.svelte";
  import ProfileMenu from "./ProfileMenu.svelte";
  import NotificationBell from "./NotificationBell.svelte";
  import { Navbar, NavBrand, NavHamburger, NavUl, NavLi } from "flowbite-svelte";
  import { Modal as STModal } from "stwui";
  import { Icon } from "@steeze-ui/svelte-icon";
  import { Close, Warning } from "@steeze-ui/material-design-icons";
  import { sendEmailVerification } from "$src/lib/utils/verifyEmail";

  let user: any;
  let profile;
  let unsubscribe;
  let verifyModal = false;

  // onMount used to defer rending until after store lookup
  onMount(() => {
    unsubscribe = authStore.subscribe(async ({ user: u, profile: p }) => {
      user = u;
      profile = p;
    });
  });
  $: unsubscribe && onDestroy(unsubscribe);

  $: loading = user === undefined;
</script>

<Navbar
  let:hidden
  let:toggle
  color="form"
  class="sticky z-[100] shadow-sm rounded-none"
  navDivClass="mx-auto flex flex-wrap max-w-7xl"
  navClass="px-2 sm:px-4 py-2.5 absolute w-full z-20 top-0 left-0 border-b"
>
  <NavHamburger on:click={toggle} class1="w-full md:flex md:w-auto md:order-1" class="mr-4" />
  <NavBrand href="/">
    <a href="/"
      ><img
        class="h-9 hidden md:block dark:hidden"
        alt="logo"
        src="/images/logos/headerLogo.webp"
      /></a
    >
    <a href="/"
      ><img
        class="h-9 block md:hidden dark:hidden"
        alt="logo"
        src="/images/logos/headerLogoMobile.webp"
      /></a
    >
    <a href="/"
      ><img
        class="h-9 hidden dark:md:block"
        alt="logo"
        src="/images/logos/headerLogoWhite.webp"
      /></a
    >
    <a href="/"
      ><img
        class="h-9 hidden dark:block dark:md:hidden"
        alt="logo"
        src="/images/logos/headerLogoWhiteMobile.webp"
      /></a
    >
  </NavBrand>
  <div class="flex-auto md:order-2" />
  <div class="flex items-center md:order-3">
    {#if !loading && !user}
      <a href={`/login?url=${$page.url.pathname}`}>
        <div
          class="text-center font-medium focus:outline-none inline-flex items-center justify-center px-5 py-2.5 text-sm text-white bg-gray-800 hover:bg-gray-900 focus:ring-gray-300 dark:bg-gray-800 dark:hover:bg-gray-900 dark:focus:ring-gray-700 rounded-lg mr-2"
        >
          {$LL.header.signIn()}
        </div>
      </a>
      <a href="/register">
        <div
          class="text-center font-medium focus:outline-none inline-flex items-center justify-center px-5 py-2.5 text-sm text-emerald-900 bg-emerald-400 dark:bg-emerald-500 hover:bg-emerald-400 focus:ring-gray-300 rounded-lg mr-2"
        >
          {$LL.header.signUp()}
        </div>
      </a>
    {:else if user}
      <a
        href="/create-ride"
        class="py-2.5 px-3 mr-2 md:mr-4 text-sm font-medium text-black focus:outline-none bg-white rounded-md border-2 border-emerald-400 hover:bg-gray-100 focus:z-10 dark:bg-gray-800 dark:text-green-400 dark:border-emerald-500 dark:hover:bg-gray-700"
        ><div class="flex items-center gap-2">
          <img class="h-4 hidden md:block dark:hidden" alt="logo" src="/images/logos/headerLogoMobile.webp" />
          <img class="h-4 hidden dark:md:block" alt="logo" src="/images/logos/headerLogoWhiteMobile.webp" />
          <div>{$LL.header.listRide()}</div>
        </div></a
      >

      <NotificationBell {profile} />
      <ProfileMenu {profile} />
    {:else if loading}
      <Spinner />
    {/if}
  </div>
  <NavUl {hidden} class="pl-4">
    <NavLi
      href={`/search${profile?.location ? `?location=${profile?.location}` : ""}`}
      active={$page.url.pathname === "/search"}
      activeClass="text-white bg-green-400 md:bg-transparent md:text-green-400 md:dark:text-green-400 dark:bg-green-400 md:dark:bg-transparent"
      >{$LL.header.search()}</NavLi
    >
    <NavLi
      href="/groups"
      active={$page.url.pathname === "/groups"}
      activeClass="text-white bg-green-400 md:bg-transparent md:text-green-400 md:dark:text-green-400 dark:bg-green-400 md:dark:bg-transparent"
      >{$LL.header.myGroups()}</NavLi
    >
    <NavLi
      href={`/cyclists${profile?.location ? `?location=${profile?.location}` : ""}`}
      activeClass="text-white bg-green-400 md:bg-transparent md:text-green-400 md:dark:text-green-400 dark:bg-green-400 md:dark:bg-transparent"
      >{$LL.header.cyclists()}</NavLi
    >
  </NavUl>
</Navbar>
{#if user && !user.emailVerified}
  <div class="min-w-full bg-yellow-300">
    <div class="max-w-7xl mx-auto p-2 flex items-center min-h-8">
      <div class="justify-start inline-flex w-4/5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="stroke-current flex-shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          ><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          /></svg
        >
        <span class="pl-2">Please check your email to complete verification.</span>
      </div>
      <a
        class="justify-end inline-flex w-1/5"
        href={"#"}
        on:click={() => {
          sendEmailVerification();
          verifyModal = true;
        }}>Resend Activation</a
      >
    </div>
  </div>
  
{/if}
{#if verifyModal}
  <STModal handleClose={() => verifyModal == false} class="flex items-center justify-center">
    <STModal.Content slot="content">
      <STModal.Content.Body slot="body">
        <button
          on:click={() => {
            verifyModal = false;
          }}
          class="w-full flex justify-end"
        >
          <Icon
            class="h-6 w-6 flex-shrink-0 dark:text-white text-gray-800 rounded-md fill-current"
            src={Close}
          />
        </button>
        <div class="flex justify-center">
          <Icon
            class="h-10 w-10 flex-shrink-0 text-yellow-300 dark:text-yellow-400 fill-current"
            src={Warning}
          />
        </div>
        <div class="text-center">
          <h1 class="mb-5 px-6 text-lg font-normal text-gray-700 dark:text-white">
            Email Verification
          </h1>

          <h3 class="mb-5 px-6 text-md font-normal text-gray-700 dark:text-white">
            <p class="pb-2">A verification email has been sent!</p>
            <p>Please check your email to complete verification</p>
          </h3>
          <button
            on:click={() => {
              verifyModal = false;
            }}
            class="mt-auto mr-2 text-white dark:text-gray-800 bg-emerald-400 hover:bg-green-500 font-medium rounded-lg text-sm px-4 py-1.5 text-center"
            >{$LL.createGroup.upgradeModal.close()}</button
          >
        </div>
      </STModal.Content.Body>
    </STModal.Content>
  </STModal>
{/if}
