<script lang="ts">
  import Header from "$lib/components/header/Header.svelte";
  import Onboarding from "$lib/components/onboarding/Onboarding.svelte";
  import Footer from "$lib/components/footer/Footer.svelte";
  import Notification from "$lib/components/ui/components/Notification.svelte";
  import CookieConsent from "$src/lib/components/footer/CookieConsent.svelte";
</script>

<div class="min-h-screen">
  <Header />
  <Onboarding />
  <div class="mx-auto pb-4">
    <slot />
    <Notification />
  </div>
  <CookieConsent />
  <Footer />
</div>
