<script lang="ts">
  import { Icon } from "@steeze-ui/svelte-icon";
  import { Cookie } from "@steeze-ui/material-design-icons";
  import posthog from "posthog-js";
  import LL from "$i18n/i18n-svelte";
  import { onMount } from "svelte";

  let consentGiven;

  onMount(() => {
    if (!localStorage.getItem("cookieConsent")) {
      consentGiven = "undecided";
    }
    else {
        consentGiven = localStorage.getItem("cookieConsent");
        console.log(consentGiven);
    }
    posthog.set_config({
      persistence: consentGiven === "yes" ? "localStorage+cookie" : "memory",
    });
  });

  function acceptCookies() {
    posthog.set_config({ persistence: "localStorage+cookie" });
    localStorage.setItem("cookieConsent", "yes");
    consentGiven = "yes";
  }

  function rejectCookies() {
    posthog.set_config({ persistence: "memory" });
    localStorage.setItem("cookieConsent", "no");
    consentGiven = "no";
  }
</script>

{#if consentGiven === "undecided"}
  <div
    id="sticky-banner"
    tabindex="-1"
    class="fixed bottom-0 start-0 z-50 flex justify-between w-full p-4 border-b border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
  >
    <div class="lg:flex items-center mx-auto">
      <p class="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
        <span
          class="inline-flex p-1 me-2 bg-gray-200 rounded-full dark:bg-gray-600 w-8 h-8 items-center justify-center flex-shrink-0"
        >
          <Icon class="w-6 h-6 stroke-2" src={Cookie} />
          <span class="sr-only">Cookie</span>
        </span>
        <span class="pr-3">{$LL.cookieConsent.text()}</span>
      </p>
      <button
        on:click={acceptCookies}
        type="button"
        class="m-1 mt-2 lg:mr-2 w-full md:w-24 text-gray-700 bg-emerald-400 border dark:border-0 border-gray-300 focus:outline-none hover:bg-emerald-500 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-emerald-400 dark:text-emerald-900 dark:hover:bg-emerald-500 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        ><div class="flex items-center justify-center">{$LL.cookieConsent.accept()}</div></button
      >
      <button
        on:click={rejectCookies}
        type="button"
        class="m-1 lg:mr-2 w-full md:w-24 text-gray-700 bg-white border dark:border-0 border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 py-2.5 mr-2 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        ><div class="flex items-center justify-center">{$LL.cookieConsent.reject()}</div></button
      >
    </div>
  </div>
{/if}
