<script lang="ts">
  import LL from "$i18n/i18n-svelte";
  import NotificationItem from "$lib/components/header/NotificationItem.svelte";
  import { queryAsStore } from "$lib/stores/firebase";
  import { db } from "$src/lib/firebase/init";
  import { notifications as appNotification } from "$src/lib/stores/notification";
  import { BellSlash } from "@steeze-ui/heroicons";
  import { Icon } from "@steeze-ui/svelte-icon";
  import { collection, doc, limit, orderBy, query, updateDoc } from "firebase/firestore";
  import { Dropdown, DropdownItem } from "flowbite-svelte";
  export let profile;

  let unreadNotifications = false;
  const THIRTY_SECONDS = 1 * 30 * 1000;
  const notificationLimit = 10;
  let notifications;

  const notificationsQuery = query(
    collection(db, "users", profile.uid, "notifications"),
    orderBy("createdAt", "desc"),
    limit(notificationLimit)
  );
  notifications = queryAsStore(notificationsQuery);

  async function readNotification() {
    unreadNotifications = false;
    $notifications.map(async (notification) => {
      if (!notification.read) {
        await updateDoc(doc(db, `users/${profile.uid}/notifications`, notification.id), {
          read: true,
        });
      }
    });
  }

  $: if ($notifications) {
    if ($notifications?.[0]?.read === false) {
      const now = new Date().getTime();

      if (now - $notifications?.[0].createdAt.toDate().getTime() < THIRTY_SECONDS) {
        appNotification.default({
          avatarUrl: $notifications?.[0]?.avatarUrl || "",
          title: $LL.settings.notifications.titles[$notifications?.[0].title](),
          displayText: `${
            $notifications?.[0]?.cyclistName || ""
          } ${$LL.settings.notifications.displayText[$notifications?.[0].displayText]()} ${
            $notifications?.[0]?.rideName ||
            $notifications?.[0]?.groupName ||
            $notifications?.[0]?.name ||
            ""
          }`,
          notificationUrl: $notifications?.[0].url,
        });
      }
    }
  }

  $: if ($notifications) {
    let tempUneadNotifications = false;
    $notifications.map((notification) => {
      if (!notification.read) tempUneadNotifications = true;
    });
    unreadNotifications = tempUneadNotifications;
  }
</script>

<button
  id="bell"
  on:click={readNotification}
  class="inline-flex items-center {unreadNotifications
    ? ''
    : 'mr-3'} text-sm font-medium text-center text-gray-500 hover:text-gray-900 focus:outline-none dark:hover:text-white dark:text-gray-400"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    class="w-6 h-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
    />
  </svg>
  {#if unreadNotifications}
    <div class="flex relative">
      <div
        class="inline-flex relative -top-2 right-3 w-3 h-3 bg-red-500 rounded-full border-2 border-white dark:border-gray-700"
      />
    </div>
  {/if}
</button>
<Dropdown
  offset={30}
  triggeredBy="#bell"
  class="w-full max-w-xs divide-y divide-gray-100 shadow dark:bg-gray-800 dark:divide-gray-600"
>
  <div slot="header" class="text-center py-2 font-bold">{$LL.header.notifications()}</div>
  <div class="max-h-96 overflow-x-auto">
    {#if $notifications.length > 0}
      {#each $notifications as notification}
        <NotificationItem {notification} />
      {/each}
    {:else}
      <DropdownItem class="flex flex-col w-80 items-center justify-center">
        <Icon class="w-12 h-12 mt-2 stroke-1" src={BellSlash} />
        <div class="font-semibold mt-3 text-gray-700 dark:text-gray-300">No Notifications</div>
      </DropdownItem>
    {/if}
  </div>
</Dropdown>
