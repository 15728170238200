<script lang="ts">
  import { signOut } from "$lib/firebase/auth";
  import LL from "$i18n/i18n-svelte";
  import { Avatar, Dropdown, DropdownHeader, DropdownItem, DropdownDivider } from "flowbite-svelte";
  import { Icon } from "@steeze-ui/svelte-icon";
  import { ExpandMore } from "@steeze-ui/material-design-icons";
  export let profile;
</script>

<div class="relative">
  <div id="avatar-menu" class="flex items-center">
    <Avatar src={profile?.photoURL || ""} />
    <Icon src={ExpandMore} class="w-7 h-7 stroke-2 text-gray-500 dark:text-gray-400" />
  </div>
  {#if profile?.tier === "premium" || profile?.tier === "club"}
    <img
      src="/images/logos/grouprides.webp"
      class="-bottom-1 left-7 absolute w-4 h-4 bg-green-400 border-white dark:border-gray-800 rounded-full"
      alt=""
    />
  {/if}
</div>
<Dropdown class="w-40" placement="bottom" triggeredBy="#avatar-menu">
  <DropdownHeader>
    {#if profile?.firstName && profile?.lastName}
      <span class="block text-sm">{profile?.firstName} {profile?.lastName}</span>
    {/if}
  </DropdownHeader>
  {#if profile?.tier != "premium" && profile?.tier != "club"}
    <DropdownItem
      href="/upgrade"
      class="border-y-2 border-emerald-400 dark:border-emerald-500 bg-slate-50 dark:bg-gray-800 dark:text-green-400"
      ><div class="flex items-center gap-2">
        <img
          class="h-4 hidden md:block dark:hidden"
          alt="logo"
          src="/images/logos/headerLogoMobile.webp"
        />
        <img
          class="h-4 hidden dark:md:block"
          alt="logo"
          src="/images/logos/headerLogoWhiteMobile.webp"
        />
        <div>{$LL.header.dropdown.upgrade()}</div>
      </div>
    </DropdownItem>
  {/if}
  <DropdownItem href="/profile/{profile?.shortId}/rides"
    >{$LL.header.dropdown.profile()}</DropdownItem
  >
  <!-- <DropdownItem>{$LL.header.dropdown.myRides()}</DropdownItem> -->
  <DropdownItem href="/settings/profile">{$LL.header.dropdown.settings()}</DropdownItem>
  <DropdownDivider />
  <DropdownItem on:click={signOut}>{$LL.header.dropdown.signOut()}</DropdownItem>
</Dropdown>
