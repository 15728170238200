<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import { Timestamp } from "firebase/firestore";
  import { updateProfile } from "$lib/firebase/auth";
  import { createForm } from "svelte-forms-lib";
  import * as yup from "yup";
  import LL from "$i18n/i18n-svelte";
  import { CalendarDays } from "@steeze-ui/heroicons";
  import { Button, DatePicker, Modal, Input, Select } from "stwui";
  import authStore from "$lib/stores/authStore";
  import { Icon } from "@steeze-ui/svelte-icon";
  import { notifications } from "$src/lib/stores/notification";

  let unsubscribe, profile, user, isModalOpen, genderOptions, selectedGender;

  let dateTime = new Date();
  dateTime.setFullYear(dateTime.getFullYear() - 30);
  let minAge = new Date()
  minAge.setFullYear(minAge.getFullYear() - 13);

  $: $form.gender = selectedGender?.value;

  $: genderOptions = [
    { value: "male", label: $LL.settings.profile.genderOptions.male() },
    { value: "female", label: $LL.settings.profile.genderOptions.female() },
    { value: "nonBinary", label: $LL.settings.profile.genderOptions.nonBinary() },
    { value: "unknown", label: $LL.settings.profile.genderOptions.noResponse() },
  ];

  onMount(async () => {
    unsubscribe = authStore.subscribe(async ({ profile: p, user: u }) => {
      profile = p;
      user = u;
    });
  });
  $: unsubscribe && onDestroy(unsubscribe);

  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      firstName: "",
      lastName: "",
      birthday: "",
      gender: "",
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      birthday: yup.string().required(),
      gender: yup.string().required(),
    }),
    validate: (values) => {
      let errs = {};
      if (values.firstName === "") errs["firstName"] = "First name is required";
      if (values.lastName === "") errs["lastName"] = "Last name is required";
      if (values.birthday === "") errs["birthday"] = "Birthday is required";
      if (values.gender === undefined) errs["gender"] = "Gender is required";
      return errs;
    },
    onSubmit: async (values) => {
      try {
        const birthdayDate = new Date(values.birthday);
        const birthday = new Timestamp(Math.round(birthdayDate.getTime() / 1000), 0);

        // Create stripe customer
        if (!profile?.subscriptionData?.customerId) {
          await fetch("/api/protected/subscription/create-user", {
            method: "POST",
            body: JSON.stringify({
              name: `${values.firstName} ${values.lastName}`,
              email: user.email,
            }),
            headers: {
              contentType: "application/json",
            },
          });
        }

        const updatedProfile = {
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          birthday: birthday,
          onboardingComplete: true,
        };

        await updateProfile(profile.uid, updatedProfile);
        isModalOpen = false;
        notifications.success({
          title: $LL.settings.profile.updateSuccessTitle(),
          displayText: $LL.settings.profile.updateSuccessText(),
        });
      } catch (e) {
        notifications.danger({
          title: $LL.settings.profile.updateFailedTitle(),
          displayText: $LL.settings.profile.updateFailedText(),
        });
      }
    },
  });

  $: isModalOpen = profile?.onboardingComplete === false;
</script>

{#if isModalOpen}
  <Modal handleClose={() => {}}>
    <Modal.Content slot="content" class="overflow-visible mt-20">
      <Modal.Content.Header slot="header">Complete your profile</Modal.Content.Header>
      <Modal.Content.Body slot="body">
        <form on:submit={handleSubmit}>
          <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Your profile will help connect you with friends and cycling groups. You can update
            privacy settings at any time from your profile.
          </p>
          <div class="pt-3 md:flex md:items-center pb-2">
            <div class="md:w-1/2 md:px-3 pb-6">
              <Input
                name="firstName"
                autocomplete="off"
                bind:value={$form.firstName}
                on:input={handleChange}
                error={$errors.firstName}
              >
                <Input.Label slot="label"
                  >First Name<span class="text-red-500 ml-1">*</span></Input.Label
                >
              </Input>
            </div>
            <div class="md:w-1/2 md:px-3 pb-3 md:pb-6">
              <Input
                name="lastName"
                autocomplete="off"
                bind:value={$form.lastName}
                on:input={handleChange}
                error={$errors.lastName}
              >
                <Input.Label slot="label"
                  >Last Name<span class="text-red-500 ml-1">*</span></Input.Label
                >
              </Input>
            </div>
          </div>
          <div class="md:flex md:items-center pb-2">
            <div class="md:w-1/2 md:px-3 pb-6 md:pb-0">
              <DatePicker
                name="birthday"
                class="w-full text-gray-600 dark:text-gray-400 [&>div]:z-50"
                max={minAge}
                error={$errors.birthday}
                bind:value={dateTime}
                on:change={handleChange}
              >
                <DatePicker.Label slot="label"
                  >Birthday<span class="ml-1 text-red-500">*</span></DatePicker.Label
                >
                <Icon
                  slot="leading"
                  src={CalendarDays}
                  class="w-5 h-5 stroke-2 text-gray-600 dark:text-gray-400"
                />
              </DatePicker>
            </div>
            <div class="md:w-1/2 md:px-3 relative">
              <Select
                name="gender"
                bind:value={selectedGender}
                on:change={handleChange}
                error={$errors.gender}
                class="text-gray-500"
                placeholder="Select an Option"
              >
                <Select.Label slot="label"
                  >Gender<span class="ml-1 text-red-500">*</span></Select.Label
                >
                <Select.Options slot="options">
                  {#each genderOptions as option}
                    <Select.Options.Option {option} />
                  {/each}
                </Select.Options>
              </Select>
            </div>
          </div>
          <Button type="primary" htmlType="submit" class="mt-3 md:mx-3 w-full md:w-32"
            >Submit</Button
          >
        </form>
      </Modal.Content.Body>
    </Modal.Content>
  </Modal>
{/if}
