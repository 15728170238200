<script lang="ts">
  import { Avatar, DropdownItem } from "flowbite-svelte";
  import { formatDate } from "$src/lib/utils/formatDate";
  import LL from "$i18n/i18n-svelte";
  import { goto } from "$app/navigation";
  import { getFollowingDetails } from "$src/lib/firebase/auth";
  import authStore from "$src/lib/stores/authStore";

  export let notification;

  let timeString = formatDate(new Date(new Date(notification.createdAt.toDate())));

  async function handleClick() {
    const followDetails = await getFollowingDetails($authStore.profile);
    authStore.update((state) => ({
      ...state,
      followDetails: followDetails,
    }));
    goto(notification.url);
  }
</script>

{#if notification.type === "user"}
  <DropdownItem on:click={handleClick} class="flex space-x-1">
    <div class="flex shrink-0 h-20 items-center justify-center">
      <Avatar size="md" class="mr-0 md:mr-3" src={notification?.avatarUrl || ""} />
    </div>
    <div class=" w-full">
      <span class="font-semibold text-gray-900 dark:text-white"
        >{$LL.settings.notifications.titles[notification?.title]()}</span
      >
      <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
        {notification?.cyclistName}
        {$LL.settings.notifications.displayText[notification?.displayText]()}
      </div>
      <div class="text-xs text-emerald-600 dark:text-emerald-500">
        {timeString}
      </div>
    </div>
  </DropdownItem>
{:else if notification.type === "ride"}
  <DropdownItem on:click={() => goto(notification.url)} class="flex space-x-1">
    <div class="flex h-20 items-center justify-center">
      <Avatar size="md" class="mr-0 md:mr-3" src={notification?.avatarUrl || ""} />
    </div>
    <div class=" w-full">
      <span class="font-semibold text-gray-900 dark:text-white"
        >{$LL.settings.notifications.titles[notification?.title]()}</span
      >
      <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
        {notification?.cyclistName}
        {$LL.settings.notifications.displayText[notification?.displayText]()}
        {notification?.rideName}
      </div>
      <div class="text-xs text-emerald-600 dark:text-emerald-500">
        {timeString}
      </div>
    </div>
  </DropdownItem>
{:else if notification.type === "groupRide"}
  <DropdownItem on:click={() => goto(notification.url)} class="flex space-x-1">
    <div class="flex h-20 items-center justify-center">
      <Avatar size="md" class="mr-0 md:mr-3" src={notification?.avatarUrl || ""} />
    </div>
    <div class=" w-full">
      <span class="font-semibold text-gray-900 dark:text-white"
        >{$LL.settings.notifications.titles[notification?.title]()}</span
      >
      <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
        {notification?.groupName}
        {$LL.settings.notifications.displayText[notification?.displayText]()}
        {notification?.rideName}
      </div>
      <div class="text-xs text-emerald-600 dark:text-emerald-500">
        {timeString}
      </div>
    </div>
  </DropdownItem>
{:else if notification.type === "ride-newUser"}
  <DropdownItem on:click={() => goto(notification.url)} class="flex space-x-1">
    <div class="flex h-20 items-center justify-center">
      <Avatar size="md" class="mr-0 md:mr-3" src={""} />
    </div>
    <div class=" w-full">
      <span class="font-semibold text-gray-900 dark:text-white"
        >{$LL.settings.notifications.titles[notification?.title]()}</span
      >
      <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
        {$LL.settings.notifications.displayText[notification?.displayText]()}
        {notification?.rideName}
      </div>
      <div class="text-xs text-emerald-600 dark:text-emerald-500">
        {timeString}
      </div>
    </div>
  </DropdownItem>
{:else if notification.type === "group-updated"}
  <DropdownItem on:click={() => goto(notification.url)} class="flex space-x-1">
    <div class="flex h-20 items-center justify-center">
      <Avatar size="md" class="mr-0 md:mr-3" src={notification?.avatarUrl || ""} />
    </div>
    <div class=" w-full">
      <span class="font-semibold text-gray-900 dark:text-white"
        >{$LL.settings.notifications.titles[notification?.title]()}</span
      >
      <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
        {notification?.cyclistName}
        {$LL.settings.notifications.displayText[notification?.displayText]()}
        {notification?.groupName}
      </div>
      <div class="text-xs text-emerald-600 dark:text-emerald-500">
        {timeString}
      </div>
    </div>
  </DropdownItem>
{:else if notification.type === "favorite-group-join" || notification.type === "group-invite" || notification.type === "group-joinRequest"}
  <DropdownItem on:click={() => goto(notification.url)} class="flex space-x-1">
    <div class="flex h-20 items-center justify-center">
      <Avatar size="md" class="mr-0 md:mr-3" src={notification?.avatarUrl || ""} />
    </div>
    <div class=" w-full">
      <span class="font-semibold text-gray-900 dark:text-white"
        >{$LL.settings.notifications.titles[notification?.title]()}</span
      >
      <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
        {notification?.cyclistName}
        {$LL.settings.notifications.displayText[notification?.displayText]()}
        {notification?.groupName}
      </div>
      <div class="text-xs text-emerald-600 dark:text-emerald-500">
        {timeString}
      </div>
    </div>
  </DropdownItem>
{:else if notification.type === "group-newUser"}
  <DropdownItem on:click={() => goto(notification.url)} class="flex space-x-1">
    <div class="flex h-20 items-center justify-center">
      <Avatar size="md" class="mr-0 md:mr-3" src={""} />
    </div>
    <div class=" w-full">
      <span class="font-semibold text-gray-900 dark:text-white"
        >{$LL.settings.notifications.titles[notification?.title]()}</span
      >
      <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
        {$LL.settings.notifications.displayText[notification?.displayText]()}
        {notification?.groupName}
      </div>
      <div class="text-xs text-emerald-600 dark:text-emerald-500">
        {timeString}
      </div>
    </div>
  </DropdownItem>
{:else if notification.type === "discussion"}
  <DropdownItem on:click={() => goto(notification.url)} class="flex space-x-1">
    <div class="flex h-20 items-center justify-center">
      <Avatar size="md" class="mr-0 md:mr-3" src={""} />
    </div>
    <div class=" w-full">
      <span class="font-semibold text-gray-900 dark:text-white"
        >{$LL.settings.notifications.titles[notification?.title]()}</span
      >
      <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
        {notification?.cyclistName}
        {$LL.settings.notifications.displayText[notification?.displayText]()}
        {notification?.name}
      </div>
      <div class="text-xs text-emerald-600 dark:text-emerald-500">
        {timeString}
      </div>
    </div>
  </DropdownItem>
{:else if notification.type === "discussion-report"}
  <DropdownItem on:click={() => goto(notification.url)} class="flex space-x-1">
    <div class="flex h-20 items-center justify-center">
      <Avatar size="md" class="mr-0 md:mr-3" src={notification?.avatarUrl || ""} />
    </div>
    <div class=" w-full">
      <span class="font-semibold text-gray-900 dark:text-white"
        >{$LL.settings.notifications.titles[notification?.title]()}</span
      >
      <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
        {notification?.cyclistName}
        {$LL.settings.notifications.displayText[notification?.displayText]()}
        {notification?.name}
      </div>
      <div class="text-xs text-emerald-600 dark:text-emerald-500">
        {timeString}
      </div>
    </div>
  </DropdownItem>
{:else if notification.type === "discussion-reply-report"}
  <DropdownItem on:click={() => goto(notification.url)} class="flex space-x-1">
    <div class="flex h-20 items-center justify-center">
      <Avatar size="md" class="mr-0 md:mr-3" src={notification?.avatarUrl || ""} />
    </div>
    <div class=" w-full">
      <span class="font-semibold text-gray-900 dark:text-white"
        >{$LL.settings.notifications.titles[notification?.title]()}</span
      >
      <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
        {notification?.cyclistName}
        {$LL.settings.notifications.displayText[notification?.displayText]()}
        {notification?.name}
      </div>
      <div class="text-xs text-emerald-600 dark:text-emerald-500">
        {timeString}
      </div>
    </div>
  </DropdownItem>
{/if}
