<script>
  import { fly } from "svelte/transition";
  import { notifications } from "$src/lib/stores/notification";
  import LL from "$i18n/i18n-svelte";
  import { Icon } from "@steeze-ui/svelte-icon";
  import { Done, Error } from "@steeze-ui/material-design-icons";
</script>

<div class="fixed top-3 sm:top-20 right-3 z-[9999]">
  {#each $notifications as notification (notification.id)}
    <div
      transition:fly={{ y: -90 }}
      id="toast-message-cta"
      class="w-full max-w-xs p-4 mb-3 border {notification.type === 'danger'
        ? 'border-red-400 dark:border-red-500'
        : 'border-emerald-400 dark:border-emerald-500'} text-gray-500 bg-white rounded-lg shadow dark:bg-gray-800 dark:text-gray-400"
      role="alert"
    >
      <div class="flex">
        {#if notification.message.avatarUrl}
          <img class="w-8 h-8 rounded-full" src={notification.message.avatarUrl} alt="Avatar" />
        {/if}
        {#if notification.type === "success"}
          <Icon
            class="w-8 h-8 stroke-4 fill-current text-emerald-400 dark:text-emerald-500"
            src={Done}
          />
        {/if}
        {#if notification.type === "danger"}
          <Icon class="w-8 h-8 stroke-4 fill-current text-red-400 dark:text-red-500" src={Error} />
        {/if}
        <div class="ms-3 text-sm font-normal">
          {#if notification.message.title}
            <span class="mb-1 text-sm font-semibold text-gray-900 dark:text-white"
              >{notification.message.title}</span
            >
          {/if}
          {#if notification.message.displayText}
            <div class="mb-2 text-sm font-normal">
              {notification.message.displayText}
            </div>
          {/if}
          {#if notification.message.notificationUrl}
            <a
              href={notification.message.notificationUrl}
              class="inline-flex px-2.5 py-1.5 text-xs font-medium text-center text-white dark:text-emerald-900 bg-emerald-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-blue-300 dark:bg-emerald-400 dark:hover:bg-emerald-500"
              >{$LL.settings.notifications.view()}</a
            >
          {/if}
        </div>
        <button
          type="button"
          on:click={() => {
            notifications.remove(notification.id);
          }}
          class="ms-auto -mx-1.5 -my-1.5 bg-white justify-center items-center flex-shrink-0 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-message-cta"
          aria-label="Close"
        >
          <span class="sr-only">Close</span>
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
    </div>
  {/each}
</div>
